const initialState = {
  onBoardingProjects: [],
  channelCreatedProjects: [],
  showLoader: false,
};

const onBoardingProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ONBOARDING_PROJECT':
      return {
        ...state,
        onBoardingProjects: action.payload,
      };
    case 'GET_CHANNEL_CREATED_PROJECT':
      return {
        ...state,
        channelCreatedProjects: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
export default onBoardingProjectReducer;
