// ** React Imports
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';
import { SocketContext, socket } from '@services/socket';
// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import 'react-toastify/dist/ReactToastify.css';
import '@styles/react/libs/toastify/toastify.scss';

// ** React Draft Wysiwyg
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@styles/react/libs/editor/editor.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Libraries
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import 'react-image-lightbox/style.css';
// ** Service Worker
import * as serviceWorker from './serviceWorker';

//Recoil
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

createRoot(document.getElementById('root')).render(
  <SocketContext.Provider value={socket}>
    <RecoilRoot>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <ToastContainer position='top-right' autoClose={5000} newestOnTop />
            <LazyApp />
          </ThemeContext>
        </Suspense>
      </Provider>
    </RecoilRoot>
  </SocketContext.Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
